import * as Sentry from "@sentry/nuxt";

Sentry.init( {
  dsn: "https://921f6e37f6de8f65e494a020e6ce9970@o4508103476838400.ingest.de.sentry.io/4508103479853136",
  tracesSampleRate: 1.0,
  enabled: !import.meta.dev,
  integrations: [
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
} );
